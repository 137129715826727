import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import bgimage from "./../../images/backgrounds/background_fot_v5.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { firebaseApp } from "./../../scripts/firebase.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./../../scripts/firebaseAdmin";

const auth = getAuth(firebaseApp);

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://spira.mtron.me/">
        SPIRA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const userCollectionRef = collection(firestore, "user");
    const userSnapshot = await getDocs(userCollectionRef);
    const users = userSnapshot.docs.map((doc) => ({
      type: doc.data().type,
      uid: doc.data().uid,
    }));
    setUsers(users);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // console.log('User ID:', user.uid);
      // console.log('User data:', users)

      const matchingUser = users.find((_user) => _user.uid === user.uid);

      if (matchingUser && matchingUser.type === "admin") {
        navigate("/sp-admin", { replace: true });
      } else {
        navigate("/profile", { replace: true });
      }
    } catch (error) {
      // console.error(error);
      toast.error("Invalid Email or Password!!!");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: isMobile ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h1"
            paragraph
            sx={{
              fontFamily: "Roboto",
              WebkitTextStroke: "3px #fff",
              textStroke: "3px #fff",
              WebkitTextFillColor: "transparent",
              textFillColor: "transparent",
              textAlign: "justify",
            }}
          >
            <center>
              Scientific Publication Inauguration Research Academy
            </center>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: "#fff" }}
        >
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#404040" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              <b>SPIRA</b>
              <br /> Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#404040",
                  ":hover": {
                    bgcolor: "#808080",
                  },
                }}
              >
                Sign In
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </ThemeProvider>
  );
}
