import * as React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  Typography,
  Container,
  Toolbar,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import "./spinner.css";
import { useLocation } from "react-router-dom";

export default function Projects() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const projectData = location.state;

  const [showModal, setShowModal] = useState(false);
  const [showProposalModal, setShowProposalModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  const toggleProposalModal = () => setShowProposalModal(!showProposalModal);

  return (
    <>
      <Container maxWidth="xl" style={{ width: "90%" }} disableGutters>
        <Toolbar />
        <Typography
          variant={isMobile ? "h3" : "h2"}
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>{projectData.topic}</b>
        </Typography>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Paper
              elevation={3}
              style={{
                padding: "2rem",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                width: "100%",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  <b>Title:</b> {projectData.title}
                </Typography>
                <Typography variant="h7" style={{ marginBottom: "20px" }}>
                  <b>Description:</b> <i>{projectData.description}</i>
                </Typography>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  {/* <center>
                    <b>Group Photo</b>
                  </center> */}
                  {projectData.group_photo ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={projectData.group_photo}
                        alt="Graphical Abstract"
                        style={{
                          maxWidth: isMobile ? "100%" : "60%",
                          height: "auto",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      />
                    </Box>
                  ) : (
                    "N/A"
                  )}
                </Typography>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  <Button variant="primary" onClick={toggleModal}>
                    Show Group Members
                  </Button>
                  <Modal
                    show={showModal}
                    onHide={toggleModal}
                    className="my-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Group Members</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {projectData.group_members.map((member, memberIndex) => (
                        <li key={memberIndex}>{member}</li>
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={toggleModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Typography>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  <Button variant="primary" onClick={toggleProposalModal}>
                    Show Research Proposal
                  </Button>
                  <Modal
                    show={showProposalModal}
                    onHide={toggleProposalModal}
                    size="lg"
                    className="my-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Research Proposal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <iframe
                        title="PDF Viewer"
                        src={projectData.proposal}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      ></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={toggleProposalModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Toolbar />
      </Container>
    </>
  );
}
