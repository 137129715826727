import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Toolbar,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../scripts/firebaseAdmin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNews = () => {
  const [news_title, setNewsTitle] = useState([""]);
  const [description, setDescription] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      description,
      news_title,
    };

    try {
      const docRef = await addDoc(collection(firestore, "news"), formData);
      toast.success("News added successfully! [" + docRef.id + "]");
      handleClear();
    } catch (e) {
      toast.error("Error adding document: " + e);
      handleClear();
    }
  };

  const handleClear = () => {
    setNewsTitle([""]);
    setDescription("");
  };

  return (
    <>
      <ToastContainer />
      <center>
        <Container
          disableGutters
          maxWidth="xl"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <Toolbar />
          <Typography
            variant="h2"
            style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
          >
            <b>Add News</b>
          </Typography>
          <Paper
            elevation={3}
            style={{
              padding: "2rem",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "90%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    label="News Title"
                    variant="outlined"
                    value={news_title}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setNewsTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    label="Description"
                    variant="outlined"
                    value={description}
                    multiline
                    rows={5}
                    maxRows={10}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleClear}
                    size="large"
                    color="secondary"
                    variant="contained"
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </center>
    </>
  );
};

export default AddNews;
