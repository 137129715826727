import React from "react";
import { Grid, Container, Typography, Toolbar } from "@mui/material";
import re7 from "./../../images/researchers/re7.webp";

const FoundersMessage = () => (
  <Container>
    <Toolbar />
    <Typography
      variant="h2"
      style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
    >
      <b>Founder Message</b>
    </Typography>
    <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        margin: "10px 0px 10px -8px",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "15px",
      }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="body1">
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            I am thrilled to introduce SPIRA (Scientific Publication
            Inauguration Research Academy), a pioneering research academy aimed
            at providing a dynamic platform to enhance research activities among
            our university students. As the founder of SPIRA, I am excited to
            bring forth a transformative initiative that will revolutionize the
            way we approach research within our academic community.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            At SPIRA, our primary goal is to empower students to become active
            contributors to the world of research and innovation. We firmly
            believe that every student possesses immense potential and a unique
            perspective that can drive ground-breaking discoveries and
            advancements. Through SPIRA, we seek to unlock that potential by
            providing a supportive environment, cutting-edge resources, and
            unparalleled opportunities for growth.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            SPIRA offers a wide range of initiatives designed to cultivate
            research skills and nurture intellectual curiosity. Students will
            have access to mentorship programs, where they can work closely with
            experienced faculty members and industry experts who will guide them
            in their research journey. We will organize research workshops,
            seminars, and training sessions, equipping students with the
            necessary tools and methodologies to conduct rigorous and impactful
            research.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            In addition to individual research projects, SPIRA will facilitate
            interdisciplinary collaborations, encouraging students to work
            across diverse fields and explore the intersections of knowledge. We
            will foster a culture of open dialogue and exchange of ideas,
            organizing research conferences and introducing a platform where
            students can present their findings, receive feedback, and engage in
            intellectual discussions.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            I invite all students to join SPIRA and embark on this exhilarating
            research journey. Together, let us shape the future of research and
            academia, unlock our full potential, and make a lasting impact on
            the country.
          </p>
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12}>
        <Grid item xs={12} md={8}>
          <Typography variant="body1">
            <ul style={{ listStyleType: "none", padding: "0" }}>
              <li>Mr. Dilshan Dissanayaka (M.Sc.)</li>
              <li>Founder of SPIRA</li>
              <li>Department of Biosystems Technology</li>
              <li>Faculty of Technology</li>
              <li>University of Ruhuna</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={re7}
            alt="Founder"
            style={{
              width: "200px",
              borderRadius: "10px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    <Toolbar />
  </Container>
);

export default FoundersMessage;
