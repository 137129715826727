import React from 'react';
import { Container, Grid, Paper, Typography, Avatar, Toolbar, Button } from '@mui/material';
import { useUser } from './../../scripts/userContext.js';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const user = useUser().user;
  const navigate  = useNavigate();

  const handleButtonPress = (path) => {
    navigate(path)
  };

  return (
    <>
      <Container maxWidth="sm" style={{height:'100vh'}}>
        <Toolbar />
        <Toolbar />
        <Paper
          elevation={3}
          style={{ padding: '2rem', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                alt={user.displayName}
                src={user.photoURL}
                sx={{ width: '200px', height: '200px' }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" align="center">
                Welcome Back, {user.displayName}!
              </Typography>
            </Grid>
          </Grid>
          <Toolbar/>
          <Grid container direction="row" alignItems="center" justifyContent="space-around">
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => handleButtonPress('/add-publication')}>
              Add Publications
            </Button>
          </Grid>
          {/* <Grid item>
            <Button variant="contained" color="primary"  onClick={() => handleButtonPress('/view-feedback')}>
              View User Feedback
            </Button>
          </Grid> */}
          {/* <Grid item>
            <Button variant="contained" color="primary">
              Button 3
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary">
              Button 4
            </Button>
          </Grid> */}
        </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Profile;
