import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyClBbbNuhrvVbeNBKytWgmxRg3ZbNUWHi4",
  authDomain: "spira-5e542.firebaseapp.com",
  projectId: "spira-5e542",
  storageBucket: "spira-5e542.appspot.com",
  messagingSenderId: "914291482485",
  appId: "1:914291482485:web:51bc8e74d3585c80f83d3c",
  measurementId: "G-502JTZTV65",
};

export const firebaseApp = initializeApp(firebaseConfig);
