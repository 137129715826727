import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography, Paper } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import Logo_SPIRA from "./../../images/logo_spira.webp";
import Logo_FOT from "./../../images/logo_fot.webp";
import "./Dashboard.css";
import ImageSlider from "../ImageSlider";
import { MenuItem, Menu } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./../../scripts/firebaseAdmin";

const defaultTheme = createTheme();

export default function Dashboard() {
  const navigate = useNavigate();

  const [anchorElAbout, setAnchorElAbout] = React.useState(null);
  const [anchorElGallery, setAnchorElGallery] = React.useState(null);
  const [anchorElResearchers, setAnchorElResearchers] = React.useState(null);
  const [anchorElProjects, setAnchorElProjects] = React.useState(null);

  const [projects, setProjects] = React.useState([]);

  const handleClickAbout = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleClickResearchers = (event) => {
    setAnchorElResearchers(event.currentTarget);
  };

  const handleClickGallery = (event) => {
    setAnchorElGallery(event.currentTarget);
  };

  const handleClickProjects = (event) => {
    setAnchorElProjects(event.currentTarget);
  };

  const handleClose = (data, project_data) => {
    setAnchorElAbout(null);
    setAnchorElGallery(null);
    setAnchorElResearchers(null);
    setAnchorElProjects(null);

    if (
      data === "/research-experts/0" ||
      data === "/research-experts/1" ||
      data === "/research-experts/2" ||
      data === "/research-experts/3" ||
      data === "/research-experts/4" ||
      data === "/research-experts/5" ||
      data === "/research-experts/6" ||
      data === "/founders-message" ||
      data === "/about-us" ||
      data === "/developers-message" ||
      data === "/map" ||
      data === "/department-details" ||
      data === "/presentations" ||
      data === "/field-visits" ||
      data === "/lectures" ||
      data === "/research"
    ) {
      navigate(data);
    } else if (data === "/projects") {
      navigate(data, { state: project_data });
    }
  };

  const fetchProjects = async () => {
    const projectsCollectionRef = collection(firestore, "projects");
    const projectsSnapshot = await getDocs(projectsCollectionRef);
    const projects = projectsSnapshot.docs.map((doc) => ({
      id: doc.id,
      path: doc.data().path,
      topic: doc.data().topic,
      title: doc.data().title,
      proposal: doc.data().proposal,
      group_members: doc.data().group_members,
      description: doc.data().description,
      group_photo: doc.data().group_photo,
    }));
    setProjects(projects);
  };

  React.useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container disableGutters>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              backgroundColor: "transparent",
              flexGrow: 1,
              // height: '100vh',
              // overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} disableGutters>
              <div className="landing-page">
                <header className="dash-header">
                  <div className="header-container">
                    <img
                      className="spira-logo header-component"
                      src={Logo_SPIRA}
                      alt="SPIRA logo"
                    />
                    <div
                      className="header-component"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h1>
                        S&nbsp;&nbsp;&nbsp;&nbsp;P&nbsp;&nbsp;&nbsp;&nbsp;I&nbsp;&nbsp;&nbsp;&nbsp;R&nbsp;&nbsp;&nbsp;&nbsp;A
                      </h1>
                      <h2>
                        <b>
                          <span className="darkfont">S</span>cientific{" "}
                          <span className="darkfont">P</span>ublication{" "}
                          <span className="darkfont">I</span>nauguration{" "}
                          <span className="darkfont">R</span>esearch{" "}
                          <span className="darkfont">A</span>cademy
                        </b>
                      </h2>
                    </div>
                    <img
                      className="fot-logo header-component"
                      src={Logo_FOT}
                      alt="SPIRA logo"
                    />
                  </div>
                </header>
                <main>
                  <div className="button-container">
                    <Link onClick={handleClickAbout} className="button">
                      About Us
                    </Link>
                    <Menu
                      anchorEl={anchorElAbout}
                      open={Boolean(anchorElAbout)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem onClick={() => handleClose("/about-us")}>
                        Head Message
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/founders-message")}
                      >
                        Founder Message
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/developers-message")}
                      >
                        Developer Message
                      </MenuItem>
                    </Menu>
                    <Link onClick={handleClickResearchers} className="button">
                      Researchers
                    </Link>
                    <Menu
                      anchorEl={anchorElResearchers}
                      open={Boolean(anchorElResearchers)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        onClick={() => handleClose("/research-experts/0")}
                      >
                        Professor E.P.S. Chandana
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/1")}
                      >
                        Dr. Thissa Karunarathna
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/5")}
                      >
                        Dr. K.M.W Rajawaththa
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/3")}
                      >
                        Dr. (Mrs.) H.C.C. De Silva
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/4")}
                      >
                        Dr. Niranjan Kannangara
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/2")}
                      >
                        Ms. W.M.C.S Jayaweera
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose("/research-experts/6")}
                      >
                        Mr. Dilshan Dissanayaka
                      </MenuItem>
                    </Menu>
                    <Link onClick={handleClickProjects} className="button">
                      Projects
                    </Link>

                    <Menu
                      anchorEl={anchorElProjects}
                      open={Boolean(anchorElProjects)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {/* <MenuItem onClick={() => handleClose("/research")}>Research</MenuItem> */}
                      {projects.map((project, index) => (
                        <MenuItem
                          key={project.id}
                          onClick={() => handleClose("/projects", project)}
                        >
                          {project.topic}
                        </MenuItem>
                      ))}
                    </Menu>

                    <Link to="/publications" className="button">
                      Publications
                    </Link>
                    <Link onClick={handleClickGallery} className="button">
                      Gallery
                    </Link>
                    <Menu
                      anchorEl={anchorElGallery}
                      open={Boolean(anchorElGallery)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem onClick={() => handleClose("/presentations")}>
                        Presentations
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("/field-visits")}>
                        Field Visits
                      </MenuItem>
                      <MenuItem onClick={() => handleClose("/lectures")}>
                        Lectures
                      </MenuItem>
                    </Menu>
                  </div>
                  <br />
                  <ImageSlider />
                  <br />
                  <Paper
                    elevation={3}
                    style={{
                      padding: "2rem",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      margin: "12px",
                    }}
                  >
                    <Typography variant="h4" style={{ marginBottom: "40px" }}>
                      <b>News Feed</b>
                    </Typography>
                  </Paper>
                </main>
              </div>
            </Container>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
