import * as React from "react";
import {
  Box,
  Typography,
  Container,
  Toolbar,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function Contacts() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const iframeHtml = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.4968661905823!2d80.53942237589872!3d6.063522528335771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae141585ad5987d%3A0x717cf948bd5444ff!2sFaculty%20of%20Technology%2C%20University%20of%20Ruhuna!5e0!3m2!1sen!2slk!4v1687378337884!5m2!1sen!2slk" width="100%" height="${
    isMobile ? "300" : "550"
  }" style="border:0; border-radius: 10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

  return (
    <>
      <Container maxWidth="xl" style={{ height: "100vh", width: "90%" }}>
        <Toolbar />
        <Typography
          variant="h2"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>University Location</b>
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "90%",
          }}
        >
          <Box
            dangerouslySetInnerHTML={{ __html: iframeHtml }}
            sx={{
              width: "100%",
              height: isMobile ? "300px" : "550px",
              boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Paper>
      </Container>
    </>
  );
}
