import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const naviagte = useNavigate();

  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#212121", color: "#fff", padding: "2rem 0 0 0" }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 4 }}>
              SPIRA
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "justify" }}>
              Scientific Publication Inauguration Research Academy (SPIRA) is a
              renowned interdisciplinary research organization at the University
              of Ruhuna's Faculty of Technology. SPIRA fosters excellence and
              promotes scientific publication in bio system technology. It
              offers research opportunities and collaborations, emphasizing
              knowledge transfer and innovation. SPIRA's experts contribute to
              scientific discovery and innovation in biotechnology, engineering,
              bioinformatics, and environmental science.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 4 }}>
              Contact
            </Typography>
            <Typography variant="body1">
              <HomeIcon color="secondary" sx={{ mr: 1 }} />
              Faculty of Technology, University of Ruhuna.
            </Typography>
            <Typography variant="body1">
              <EmailIcon color="secondary" sx={{ mr: 1 }} />
              spira.bst@gmail.com
            </Typography>
            <Typography variant="body1">
              <PhoneIcon color="secondary" sx={{ mr: 1 }} />
              041 22 94 750
            </Typography>
            <Typography variant="body1">
              <PhoneIcon color="secondary" sx={{ mr: 1 }} />
              074 27 53 042
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 4 }}>
              Feedback
            </Typography>
            <Typography variant="body1">
              If you have any feedback. Please send the through our feedback
              form.
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ padding: "10px", margin: "20px 0 0 0" }}
              onClick={() => naviagte("/add-feedback")}
            >
              Send Us Your Feedback
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          mt: 4,
          py: 2,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        © {new Date().getFullYear()} SPIRA.MTRON.ME
      </Box>
    </Box>
  );
};

export default Footer;
