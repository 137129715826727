import * as React from "react";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./../../scripts/firebaseAdmin";
import {
  Typography,
  Container,
  Toolbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function Feedbacks() {
  const [feedbacks, setFeedbacks] = useState([]);

  const fetchFeedbacks = async () => {
    const feedbackCollectionRef = collection(firestore, "feedback");
    const feedbackSnapshot = await getDocs(feedbackCollectionRef);
    const feedbackData = feedbackSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setFeedbacks(feedbackData);
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ height: "100vh", width: "90%" }}
        disableGutters
      >
        <Toolbar />
        <Typography
          variant="h2"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>User Feedbacks</b>
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "90%",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Feedback</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <TableCell>{feedback.name}</TableCell>
                    <TableCell>{feedback.email}</TableCell>
                    <TableCell>{feedback.feedback}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
}
