import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Toolbar,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../scripts/firebaseAdmin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddProject = () => {
  const [group_members, setGroupMembers] = useState([""]);
  const [description, setDescription] = useState("");
  const [group_photo, setGroupPhoto] = useState("");
  const [proposal, setProposal] = useState("");
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");

  const handleAddMember = () => {
    setGroupMembers([...group_members, ""]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      description,
      group_members,
      group_photo,
      proposal,
      title,
      topic,
    };

    try {
      const docRef = await addDoc(collection(firestore, "projects"), formData);
      toast.success("Project added successfully! [" + docRef.id + "]");
      handleClear();
    } catch (e) {
      toast.error("Error adding document: " + e);
      handleClear();
    }
  };

  const handleClear = () => {
    setGroupMembers([""]);
    setDescription("");
    setGroupPhoto("");
    setProposal("");
    setTitle("");
    setTopic("");
  };

  return (
    <>
      <ToastContainer />
      <center>
        <Container
          disableGutters
          maxWidth="xl"
          style={{ width: "100%", marginBottom: "30px" }}
        >
          <Toolbar />
          <Typography
            variant="h2"
            style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
          >
            <b>Add Project</b>
          </Typography>
          <Paper
            elevation={3}
            style={{
              padding: "2rem",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              width: "90%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Title"
                    variant="outlined"
                    value={title}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Topic"
                    variant="outlined"
                    value={topic}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setTopic(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    label="Description"
                    variant="outlined"
                    value={description}
                    multiline
                    rows={5}
                    maxRows={10}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Group Photo Link"
                    variant="outlined"
                    value={group_photo}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setGroupPhoto(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Proposal Link"
                    variant="outlined"
                    value={proposal}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": { color: "black" },
                    }}
                    onChange={(e) => setProposal(e.target.value)}
                  />
                </Grid>
                {group_members.map((member, index) => (
                  <Grid item xs={12} sm={3} key={index}>
                    <TextField
                      label={`Member ${index + 1}`}
                      variant="outlined"
                      value={member}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": { color: "black" },
                      }}
                      onChange={(e) => {
                        const newGroupMembers = [...group_members];
                        newGroupMembers[index] = e.target.value;
                        setGroupMembers(newGroupMembers);
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={3}>
                  <Button onClick={handleAddMember}>Add Member</Button>
                </Grid>
                <Grid item xs={12} sm={9} />
                <Grid item xs={6} sm={6}>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleClear}
                    size="large"
                    color="secondary"
                    variant="contained"
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </center>
    </>
  );
};

export default AddProject;
