import React from "react";
import { auth } from "../../scripts/firebaseAdmin.js";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { Toolbar, Typography, TextField, Button, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const SignUp = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, displayName, imageUrl } = e.target.elements;

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email.value,
        password.value
      );

      await updateProfile(user, {
        photoURL: `${imageUrl.value}`,
        displayName: `${displayName.value}`,
      });

      toast("User Successfully created!!!");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        component="form"
        onSubmit={handleSubmit}
        maxWidth="400px"
        p={4}
        borderRadius={8}
        gap={2}
        sx={{ background: "rgba(255, 255, 255, 0.7)" }}
      >
        <Toolbar>
          <Typography variant="h4" component="div">
            Sign Up
          </Typography>
        </Toolbar>
        <TextField
          label="Email"
          type="email"
          name="email"
          placeholder="Email"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          placeholder="Password"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Display Name"
          type="text"
          name="displayName"
          placeholder="Display Name"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="Image URL"
          type="url"
          name="imageUrl"
          placeholder="Image URL"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </Grid>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
