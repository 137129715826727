import * as React from "react";
import { Typography, Container, Toolbar, Paper } from "@mui/material";
// import {useMediaQuery, useTheme} from '@mui/material';

export default function DepartmentDetails() {
  //   const theme = useTheme();
  //   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Container
        maxWidth="xl"
        style={{ height: "100vh", width: "90%" }}
        disableGutters
      >
        <Toolbar />
        <Typography
          variant="h2"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>BST Department Details</b>
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "90%",
          }}
        ></Paper>
      </Container>
    </>
  );
}
