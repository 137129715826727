import * as React from "react";
import { Button, Typography, Container, Toolbar, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  const buttons = [
    { label: "Add Project", onClick: () => navigate("/add-project") },
    {
      label: "Add Publication",
      onClick: () => navigate("/add-publication"),
    },
    {
      label: "Add News",
      onClick: () => navigate("/add-news"),
    },
  ];

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ height: "100vh", width: "90%" }}
        disableGutters
      >
        <Toolbar />
        <Typography
          variant="h2"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>Admin Dashboard</b>
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: "2rem",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "90%",
          }}
        >
          {buttons.map((button, index) => (
            <Button
              key={index}
              variant="contained"
              color="primary"
              onClick={button.onClick}
              style={{ margin: "10px" }}
            >
              {button.label}
            </Button>
          ))}
        </Paper>
      </Container>
    </>
  );
}
