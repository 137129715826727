import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import ResearcherList from "./components/ResearcherList";
import Publications from "./components/Publications";
import Presentations from "./components/Gallery/Presentations";
import FieldVisits from "./components/Gallery/FieldVisits";
import Lectures from "./components/Gallery/Lectures";
import Projects from "./components/Projects";
import Login from "./components/Login";
import FoundersMessage from "./components/FoundersMessage";
import AboutUs from "./components/AboutUs";
import DevelopersMessage from "./components/DevelopersMessage";
import NavBar from "./components/NavBar";
import { firebaseApp } from "./scripts/firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserContext from "./scripts/userContext";
import PrivateRoute from "./scripts/privateRoute";
import LoginRoute from "./scripts/loginRoute";
import SignUp from "./components/SignUp";
import Profile from "./components/Profile";
import PublicationForm from "./components/PublicationForm";
import Contacts from "./components/Contacts";
import DepartmentDetails from "./components/DepartmentDetails";
import UserFeedbackForm from "./components/UserFeedbackForm";
import Feedbacks from "./components/Feedbacks";
import Footer from "./components/Footer";

import AdminDashboard from "./components/Admin/Dashboard";
import AddProject from "./components/Admin/AddProject";
import AddNews from "./components/Admin/AddNews";

const auth = getAuth(firebaseApp);

function App() {
  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const resetCurrentUser = (message) => {
    setCurrentUser(null);
  };

  return (
    <UserContext.Provider
      value={{ user: currentUser, resetUser: resetCurrentUser }}
    >
      <NavBar />
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="/login" element={<LoginRoute />}>
          <Route index element={<Login />} />
        </Route>

        <Route path="/profile" element={<PrivateRoute />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="/add-project" element={<PrivateRoute />}>
          <Route index element={<AddProject />} />
        </Route>
        <Route path="/add-news" element={<PrivateRoute />}>
          <Route index element={<AddNews />} />
        </Route>
        <Route path="/add-publication" element={<PrivateRoute />}>
          <Route index element={<PublicationForm />} />
        </Route>
        <Route path="/view-feedback" element={<PrivateRoute />}>
          <Route index element={<Feedbacks />} />
        </Route>

        <Route path="/sp-admin" element={<PrivateRoute />}>
          <Route index element={<AdminDashboard />} />
        </Route>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/founders-message" element={<FoundersMessage />} />
        <Route
          path="/research-experts/:resercher_id"
          element={<ResearcherList />}
        />
        <Route path="/publications" element={<Publications />} />
        <Route path="/presentations" element={<Presentations />} />
        <Route path="/lectures" element={<Lectures />} />
        <Route path="/field-visits" element={<FieldVisits />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/developers-message" element={<DevelopersMessage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/department-details" element={<DepartmentDetails />} />
        <Route path="/add-feedback" element={<UserFeedbackForm />} />

        <Route path="*" element={<Dashboard />} />
      </Routes>
      <Footer />
    </UserContext.Provider>
  );
}

export default App;
