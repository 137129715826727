import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  Toolbar,
  Container,
} from "@mui/material";
import { firestore } from "../../scripts/firebaseAdmin";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const UserFeedbackForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError("Email is invalid");
      return;
    }

    const feedbackData = {
      name,
      email,
      feedback,
    };

    try {
      await addDoc(collection(firestore, "feedback"), feedbackData);
      toast("Feedback added successfully");
    } catch (error) {
      toast("Error adding feedback: " + error);
    }

    setName("");
    setEmail("");
    setFeedback("");
    setError(null);
  };

  return (
    <>
      <Container maxWidth="sm">
        <Toolbar />
        <Typography
          variant="h2"
          align="center"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>Add Feedback</b>
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Paper
            elevation={3}
            style={{
              padding: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              margin: "0 0 40px 0",
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item />
              <Grid item>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                {error && (
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <TextField
                  label="Feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                  multiline
                  rows={5}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Add Feedback
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default UserFeedbackForm;
