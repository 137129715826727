import React from "react";
import { Grid, Container, Typography, Toolbar } from "@mui/material";
import re2 from "./../../images/researchers/re2.webp";

const AboutUs = () => (
  <Container>
    <Toolbar />
    <Typography
      variant="h2"
      style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
    >
      <b>Head Message</b>
    </Typography>
    <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        margin: "10px 0px 10px -8px",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "15px",
      }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="body1">
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Research is the cornerstone of knowledge creation and advancement in
            any field. It allows us to explore uncharted territories, challenge
            existing theories, and discover innovative solutions to real-world
            problems. By encouraging our students to actively participate in
            research activities, we not only empower them to become independent
            thinkers but also equip them with the skills and knowledge needed to
            excel in their respective domains. As the Department Head, I
            strongly believe in the immense value that research activities bring
            to our academic community, and I am excited to provide an enhanced
            platform for students to engage in meaningful research endeavours.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            SPIRA will serve as a dedicated hub for research-related activities,
            offering a supportive environment where students can engage in
            interdisciplinary collaborations, receive guidance from experienced
            faculty members, and access valuable resources and facilities. Our
            goal is to create a vibrant research community that nurtures
            curiosity, critical thinking, and creativity.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Through the Research Academy, students will have the opportunity to
            work on research projects aligned with their interests and academic
            pursuits. They will be encouraged to explore emerging fields,
            conduct experiments, analyse data, and present their findings
            through various mediums such as conferences, publications, and
            presentations. We will also organize workshops, seminars, and guest
            lectures by renowned researchers to expose our students to the
            latest trends and methodologies in research.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            By establishing the SPIRA, we aim to create a research-driven
            ecosystem that fosters intellectual growth and empowers students to
            make meaningful contributions to their respective fields. We
            envision a future where our students become catalysts for change,
            leading ground-breaking research projects and pushing the boundaries
            of knowledge. I invite all students and faculty members to actively
            engage with the Research Academy, embrace the spirit of inquiry, and
            embark on this exciting journey of exploration and discovery. Let us
            together cultivate a culture of research excellence that propels our
            faculty to new heights of academic achievement.
          </p>
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12}>
        <Grid item xs={12} md={8}>
          <Typography variant="body1">
            <ul style={{ listStyleType: "none", padding: "0" }}>
              <li>Dr. Thissa Karunarathna (Senior Lecturer)</li>
              <li>Head,</li>
              <li>Department of Biosystems Technology,</li>
              <li>Faculty of Technology,</li>
              <li>University of Ruhuna.</li>
              <li>
                <b>Email: thissa@btec.ruh.ac.lk</b>
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={re2}
            alt="Founder"
            style={{
              width: "200px",
              borderRadius: "10px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    <Toolbar />
  </Container>
);

export default AboutUs;
