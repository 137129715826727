import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  Toolbar,
  Container,
} from "@mui/material";
import { firestore } from "./../../scripts/firebaseAdmin";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";

const PublicationForm = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [year, setYear] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const publicationData = {
      title,
      author,
      year,
    };

    try {
      await addDoc(collection(firestore, "publications"), publicationData);
      toast("Publication added successfully");
    } catch (error) {
      toast("Error adding publication: " + error);
    }

    setTitle("");
    setAuthor("");
    setYear("");
  };

  return (
    <>
      <Container maxWidth="sm" style={{ height: "100vh" }}>
        <Toolbar />
        <Typography
          variant="h2"
          align="center"
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>Add Publication</b>
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Paper
            elevation={3}
            style={{
              padding: "30px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item />
              <Grid item>
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Add Publication
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default PublicationForm;
