import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container, Typography, Toolbar } from "@mui/material";

import re1 from "./../../images/researchers/re1.webp";
import re2 from "./../../images/researchers/re2.webp";
import re3 from "./../../images/researchers/re3.webp";
import re4 from "./../../images/researchers/re4.webp";
import re5 from "./../../images/researchers/re5.webp";
import re6 from "./../../images/researchers/re6.webp";
import re7 from "./../../images/researchers/re7.webp";

const researchers = [
  {
    name: "Professor E.P.S. Chandana",
    message:
      "I’m expertise in the fields of Plant Based Medicines, Environmental Science, and Molecular Biology. With a passion for exploring the potential of plant-derived compounds, my research focuses on uncovering the medicinal properties and chemical compositions of various plant species. My works not only contributes to the development of natural remedies but also highlights the importance of sustainable practices and environmental conservation. Through my investigations into the molecular mechanisms underlying plant growth and adaptation, I bring a deeper understanding of the intricate biological processes that govern plant life.",
    researchInterests: [
      "Plant Based Medicines Environmental Science.",
      "Research Methodology.",
      "Biochemistry.",
      "Molecular Biology and Biotechnology.",
    ],
    photo: re1,
  },
  {
    name: "Dr. Thissa Karunarathna",
    message:
      "My research centers around investigating the impact of morphological traits on blister blight resistance in the tea plant. Specifically, I aim to understand how certain physical characteristics of the tea plant contribute to its ability to resist blister blight disease. Additionally, my research involves conducting in silico structural homology modeling of the EST073 motif coding protein found in tea (Camellia sinensis). This modeling approach allows for a deeper exploration of the protein's structure and function. Overall, my research interests align with the fields of molecular genetics, plant biotechnology, and the physiochemical properties of plants. Through these interdisciplinary approaches, I aim to unravel the genetic and physiological mechanisms underlying resistance to diseases in tea plants and contribute to the advancement of plant science and biotechnology.",
    researchInterests: [
      "Molecular genetics.",
      "Phytochemistry.",
      "Plant physiology.",
    ],
    photo: re2,
  },
  {
    name: "Ms. W.M.C.S Jayaweera",
    message:
      "I specialize in research that centers around two primary areas: developing compost and investigating the applicability of nursery technology for enhancing the cultivation of turmeric, along with other field practices aimed at improving overall field productivity. My research interests revolve around the interdisciplinary domains of Environmental Science, Ecology, Crop Science, Soil Science, and Plant Physiology. By exploring these interconnected fields, I strive to contribute to sustainable agricultural practices and gain a deeper understanding of the intricate relationships between the environment, crops, soil health, and plant physiology.",
    researchInterests: [
      "Phytotoxicity.",
      "Bioinformatics.",
      "Genotoxic effects of pollutants.",
      "Agricultural plants.",
    ],
    photo: re3,
  },
  {
    name: "Dr. Chandani Chalanika",
    message:
      "My research primarily centers around investigating the impacts of heat stress on the growth, photosynthetic pigments, oxidative damage, and competitive capacity of submerged macrophytes. I am particularly interested in understanding how water temperature fluctuations affect these crucial aspects of macrophyte biology. My research interests encompass a multidisciplinary approach, combining elements of Water Science, Irrigation and Water Management, and Environmental Science. Through this interdisciplinary lens, I aim to contribute to our knowledge of the intricate dynamics between aquatic ecosystems, climate change, and the resilience of submerged macrophytes.",
    researchInterests: [
      "Fish feed formulation.",
      "Ornamental fish breeding.",
      "Environmental monitoring.",
      "Environmental stresses on aquatic organisms.",
      "Aquatic plants.",
    ],
    photo: re4,
  },
  {
    name: "Dr. Niranjan Kannangara",
    message:
      "My research centers around examining the effects of meteorological parameters on the abundance of malaria vectors, as well as exploring the impact of water quality parameters on rock pools inhabited by Anopheles larvae. Through this investigation, I aim to shed light on the intricate relationship between environmental factors, vector populations, and the spread of malaria. My research interests lie within the realms of Ecology and Entomology, as I strive to understand the ecological dynamics and behaviors of disease-carrying mosquitoes. By combining these fields, I hope to contribute to the development of effective strategies for vector control and ultimately reduce the burden of malaria on affected populations.",
    researchInterests: [
      "GIS mapping.",
      "Climate Change.",
      "Renewable Energy.",
      "Quality Assurance.",
      "Waste Management.",
      "Environmental Monitoring.",
      "Medical Entomology.",
    ],
    photo: re5,
  },
  {
    name: "Dr. K.M.W Rajawaththa",
    message:
      "My research primarily revolves around the development of a Decision Support System for analyzing rainfall data and selecting the optimal solution depth for hydroponically grown lettuce. I am particularly interested in creating a comprehensive tool that can assist in making informed decisions based on rainfall patterns and their impact on hydroponic lettuce cultivation. Additionally, my research interests extend to the realms of hydroponics culture, carbon footprint calculations, and crop growth modeling. By exploring these areas, I aim to enhance our understanding of sustainable cultivation practices, minimize environmental impact, and optimize crop growth in controlled environments.",
    researchInterests: [
      "Carbon Footprint Calculations.",
      "Hydroponics Culture.",
      "ICT applications in Agriculture and Biosystems Technology.",
      "Crop growth modelling.",
    ],
    photo: re6,
  },
  {
    name: "Mr. Dilshan Dissanayaka",
    message:
      "My research primarily centers around studying the role of transmembrane proteins, specifically TMEM158, in various cancers, with a particular focus on bladder cancer. I aim to investigate the functional significance and implications of TMEM158 expression in cancer development and progression. Furthermore, I am intrigued by exploring the unintended effects of environmental pollutants on aquatic organisms, particularly fish, and understanding the molecular and physiological responses to such exposures. My research interests span across cell biology, analyzing signaling pathways involved in cancers, and delving into the field of environmental toxicology. By integrating these disciplines, I aspire to contribute to our understanding of cancer biology, signaling cascades, and the impact of environmental toxins on aquatic ecosystems, ultimately promoting human and environmental health.",
    researchInterests: [
      "Cancer biology.",
      "Signalling pathway analysis.",
      "Bioinformatics.",
      "Aquatic toxicology.",
    ],
    photo: re7,
  },
];

const ResearcherList = () => {
  let { resercher_id } = useParams();
  const researcher =
    researchers[
      parseInt(resercher_id > researchers.length - 1 ? 0 : resercher_id, 10)
    ];

  return (
    <Container>
      <Toolbar />
      <Typography
        variant="h2"
        style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
      >
        <b>Researchers</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid
          container
          spacing={1}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            margin: "10px 0px 10px 15px",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "15px",
          }}
        >
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ margin: "5px" }}>
                <b>{researcher.name}</b>
              </Typography>
              <img
                src={researcher.photo}
                alt="Researcher"
                style={{
                  width: "250px",
                  margin: "5px",
                  borderRadius: "10px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: "10px" }}>
                <b>Research Interests</b>
              </Typography>
              <Typography variant="body1">
                <ul>
                  {researcher.researchInterests.map((interest, index) => (
                    <li key={index}>{interest}</li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              <b>Research Overview</b>
            </Typography>
            <Typography variant="body1">{researcher.message}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Toolbar />
    </Container>
  );
};

export default ResearcherList;
