import * as React from "react";
import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./../../../scripts/firebaseAdmin";
import {
  Typography,
  Container,
  Toolbar,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import "./spinner.css";

export default function Presentations() {
  const [presentations, setPresentations] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchPresentations = async () => {
    const presentationCollectionRef = collection(firestore, "presentation");
    const presentationSnapshot = await getDocs(presentationCollectionRef);
    const presentations = presentationSnapshot.docs.map((doc) => ({
      id: doc.id,
      videoUrl: doc.data().videoUrl,
      topic: doc.data().topic,
    }));
    setPresentations(presentations);
    setLoading(false);
  };

  useEffect(() => {
    fetchPresentations();
  }, []);

  return (
    <>
      <Container maxWidth="xl" style={{ width: "90%" }} disableGutters>
        <Toolbar />
        <Typography
          variant={isMobile ? "h3" : "h2"}
          style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
        >
          <b>Presentations</b>
        </Typography>
        {loading ? (
          <center>
            <div className="spinner" />
          </center>
        ) : (
          <Grid container spacing={isMobile ? 2 : 4}>
            {presentations.map((presentation, index) => (
              <Grid item xs={12} key={index} style={{ marginBottom: "20px" }}>
                <Paper
                  elevation={3}
                  style={{
                    padding: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    width: "100%",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h4" style={{ marginBottom: "20px" }}>
                      {presentation.topic}
                    </Typography>
                    <iframe
                      src={presentation.videoUrl}
                      width={isMobile ? "100%" : "640"}
                      height={isMobile ? "auto" : "360"}
                      allow="autoplay"
                      style={{ border: "none" }}
                      title={`video-${index}`}
                    ></iframe>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
        <Toolbar />
      </Container>
    </>
  );
}
