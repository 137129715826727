import React from "react";
import { Grid, Container, Typography, Toolbar } from "@mui/material";
import dev1 from "./../../images/developers/dev1.webp";
import dev2 from "./../../images/developers/dev2.webp";

const DevelopersMessage = () => (
  <Container>
    <Toolbar />
    <Typography
      variant="h2"
      style={{ marginTop: "20px", marginBottom: "40px", color: "white" }}
    >
      <b>Developer Message</b>
    </Typography>
    <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        margin: "10px 0px 10px -8px",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "15px",
      }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="body1">
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Welcome to SPIRA (Scientific Publication Inauguration Research
            Academy), the official research publication website of the
            University of Ruhuna Faculty of Technology. We are delighted to have
            you here!
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            SPIRA was established under the guidance and advice of Mr. Dilshan
            Dissanayaka (M.Sc.), the esteemed founder of SPIRA and a member of
            the Department of Biosystems Technology at the Faculty of
            Technology, University of Ruhuna. With his expertise and dedication,
            SPIRA aims to provide a platform for researchers and scholars to
            share their valuable scientific work and contribute to the
            advancement of knowledge.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            At SPIRA, we believe in the power of research and its impact on
            society. Our website serves as a hub for researchers, academicians,
            and professionals from various disciplines to showcase their
            research findings, exchange ideas, and collaborate on innovative
            projects. We are committed to fostering a vibrant academic community
            and promoting interdisciplinary research that addresses real-world
            challenges.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            As you explore our website, you will find a wealth of research
            publications spanning a wide range of fields, including but not
            limited to engineering, technology, biosystems, and more. Our
            user-friendly interface makes it easy to navigate through the latest
            articles, search for specific topics, and access valuable resources.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            SPIRA is dedicated to maintaining the highest standards of quality
            and integrity in research. All submissions undergo a rigorous
            peer-review process by renowned experts in the respective fields to
            ensure the validity and reliability of the published work. We strive
            to foster a culture of excellence and intellectual rigor, promoting
            scientific advancement and fostering collaboration within the
            research community.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            We invite researchers, scholars, and students from around the world
            to submit their original research articles, review papers, and case
            studies to SPIRA. By sharing your work with us, you contribute to
            the collective knowledge and inspire future generations of
            researchers.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Thank you for visiting SPIRA. We encourage you to explore our
            website, engage in discussions, and join us in our mission to
            promote scientific publication and research excellence. Together,
            let's embark on a journey of discovery and make a lasting impact on
            the world.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Should you have any questions or suggestions, please do not hesitate
            to reach out to us. We value your feedback and are committed to
            continuously improving SPIRA to better serve the research community.
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            Best regards,
          </p>
          <p style={{ textAlign: "justify", paddingRight: "15px" }}>
            <b>
              Joseph Rasanjana, Asin Senjith
              <br />
              Website Developers
              <br />
              SPIRA
            </b>
          </p>
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12}>
        <Grid xs={12} md={6}>
          <Grid item xs={12} md={4}>
            <img
              src={dev1}
              alt="Joseph Rasanjana"
              style={{
                width: "200px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <ul style={{ listStyleType: "none", padding: "0" }}>
                <li>
                  <b>Joseph Rasanjana</b>
                </li>
                <li>Email: joe@mtron.me</li>
                <li>
                  Linkedin:{" "}
                  <a
                    href="https://www.linkedin.com/in/josephrasanjana/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Joseph Rasanjana
                  </a>
                </li>
                <li>
                  GitHub:{" "}
                  <a
                    href="https://github.com/JosephLahiru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Joseph Rasanjana
                  </a>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid item xs={12} md={4}>
            <img
              src={dev2}
              alt="Asin Senjith"
              style={{
                width: "200px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <ul style={{ listStyleType: "none", padding: "0" }}>
                <li>
                  <b>Asin Senjith</b>
                </li>
                <li>Email: asinsenjith@gmail.com</li>
                <li>
                  Linkedin:{" "}
                  <a
                    href="https://www.linkedin.com/in/ashen-senjith-913532234/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Asin Senjith
                  </a>
                </li>
                <li>
                  GitHub:{" "}
                  <a
                    href="https://github.com/AsinSenjith"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Asin Senjith
                  </a>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Toolbar />
  </Container>
);

export default DevelopersMessage;
